import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const SwingTripsTable = ({ swingtrips }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Ride Type",
        accessor: (trip) => {
          return trip.rideType === "offer"
            ? "Vehicle Available"
            : "Need Vehicle";
        },
      },
      {
        Header: "Created By",
        accessor: "createdByName",
      },
      {
        Header: "From City",
        accessor: "fromCityName",
      },
      {
        Header: "To City",
        accessor: "toCityName",
      },

      {
        Header: "Start Date",
        accessor: (trip) => {
          return trip.startDate ? trip.startDate.split(" ")[0] : "NA";
        },
      },

      {
        Header: "End Date",
        accessor: (trip) => {
          return trip.endDate ? trip.endDate.split(" ")[0] : "NA";
        },
      },
      {
        Header: "Vehicle Category",
        accessor: "vehicleCategoryName",
      },
      {
        Header: "Trip Cost",
        accessor: (trip) => {
          return trip.tripCost === 0 ? "NA" : trip.tripCost;
        },
      },
      // {
      //   Header: "Active",
      //   accessor: "verificationStatus",
      // },
    ],
    []
  );

  var data = React.useMemo(() => swingtrips, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      path={"/swingRide"}
      extras={true}
      columns={columns}
      data={data}
    />
  );

  return <TableStyles>{swingtrips ? tableRender : " "}</TableStyles>;
};
