import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { connect, useSelector } from "react-redux";
import { fetchSwingTrips } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { AgenciesSection as SwingTripsSection } from "../style/agencies";
import { SwingTripsTable } from "../components/SwingTripsTable";
import { BallBeat } from "react-pure-loaders";
function SwingTripsContainer({ swingTripsData, fetchSwingTrips }) {
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    // console.log("fetchSwingTrips being called")
    if (swingTripsData.swingtrips.length === 0) {
      fetchSwingTrips();
    }
  }, [fetchSwingTrips]);
  // console.log(swingTripsData);
  return (
    <>
      <GlobalStyle />
      <Layout>
        <SwingTripsSection>
          {swingTripsData.loadingswingtrips ? (
            <h2>
              Loading Swing Trips{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={swingTripsData.loadingswingtrips}
                />
              </span>{" "}
            </h2>
          ) : swingTripsData.errorswingtrips ? (
            <>
              <h2>ERROR ⚠️ FETCHING Swing Trips</h2>
              <br />
              <p style={{ color: "grey" }}>{swingTripsData.errorswingtrips}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              <h1>Swing Trips List</h1>
              <br />
              <SwingTripsTable swingtrips={swingTripsData.swingtrips} />
            </>
          )}
        </SwingTripsSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    swingTripsData: state.swing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSwingTrips: () => dispatch(fetchSwingTrips()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwingTripsContainer);
